@import '~antd/es/style/themes/dark.less';
@import "~antd/dist/antd.dark.less";

@font-face {
    font-family: "FF Oxide Solid";
    src: url("./fonts/ff_oxide_solid.eot");
    src: url("./fonts/ff_oxide_solid_iefix.eot") format("embedded-opentype"),
         url("./fonts/ff_oxide_solid.woff2") format("woff2"),
         url("./fonts/ff_oxide_solid.woff") format("woff"),
         url("./fonts/ff_oxide_solid.ttf") format("truetype"),
         url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.svg#FF Oxide Solid") format("svg");

    //src: url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.eot");
    //src: url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.eot?#iefix") format("embedded-opentype"),
    //url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.woff2") format("woff2"),
    //url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.woff") format("woff"),
    //url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.ttf") format("truetype"),
    //url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.svg#FF Oxide Solid") format("svg");
  }

@font-face {
  font-family: "Readex Pro Bold";
  src: url("./fonts/ReadexPro-bold.ttf");
  src: url("./fonts/ReadexPro-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Readex Pro Regular";
  src: url("./fonts/ReadexPro-Regular.ttf");
  src: url("./fonts/ReadexPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Readex Pro SemiBold";
  src: url("./fonts/ReadexPro-SemiBold.ttf");
  src: url("./fonts/ReadexPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Readex Pro Medium";
  src: url("./fonts/ReadexPro-Medium.ttf");
  src: url("./fonts/ReadexPro-Medium.ttf") format("truetype");
}

@primary-color: #ff00a8;
@popover-background: #1a2029;

@import "./ant-custom.less";
@import "~antd/dist/antd.dark.less";

body {
  --row-highlight: @background-color-base;
}

small {
  font-family: "Readex Pro Bold";
}

h1 {
  font-family: "Readex Pro Bold";
}

h2 {
  font-family: "Readex Pro SemiBold";
}

h3 {
  font-family: "Readex Pro SemiBold";
}

h4 {
  font-family: "Readex Pro SemiBold";
}

p {
  font-family: "Readex Pro Medium";
}

.app-title {
  font-family: "Readex Pro Bold";
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  padding-left: 10px;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

.app-title:hover {
  color: #ff2598;
}

.ant-layout-header {
  align-items: center;
  height: 60px;
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 64px;
  display: flex;
}

.ant-table-thead {
  display: none;
}

.ant-table-row-level-0 {
  background-color: #0A0A0A;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.App-Bar {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0px;
  position: relative;
  padding: 1rem 0.6rem 1rem 0.6rem;
  z-index: 2;
  background-color: #0A0A0A;

  .ant-menu-horizontal {
    border-bottom-color: transparent;
    background-color: transparent;
    line-height: inherit;
    font-size: 16px;
    margin: 0 10px;

    .ant-menu-item {
      margin: 0 10px;
      color: lightgrey;
      height: 35px;
      line-height: 35px;
      border-width: 0px !important;
    }

    .ant-menu-item:hover {
      color: white;
      border-width: 0px !important;
    }

    .ant-menu-item-selected {
      font-weight: bold;
    }
  }
}

.App-Bar-left {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
}

.App-Bar-right {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-self: flex-end;
}

.wallet-wrapper {
  padding-left: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.wallet-key {
  padding: 0.1rem 0.5rem 0.1rem 0.7rem;
  margin-left: 0.3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}

em {
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}

//.wallet-key {
//  padding: 0.1rem 0.5rem 0.1rem 0.7rem;
//  margin-left: 0.3rem;
//  border-radius: 0.5rem;
//  display: flex;
//  align-items: center;
//}

.ant-notification {
  a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.ant-layout-content {
  display: flex;
  overflow: auto;
  padding: 0 10px !important;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card-fill {
  height: 100%;
}

.card-row {
  box-sizing: border-box;
  margin: 5px 0px;
  min-width: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .card-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    text-align: left;
    margin: 0px;
    min-width: 0px;
    font-size: 14px;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .small {
    font-size: 11px;
  }
}

.token-input {
  display: flex;
  align-items: center;
  border: 1px solid grey;
  padding: 0px 10px;
  margin: 5px 0px;
}

[class="ant-layout-header"] {
  height: 16px !important;
}

.space-label {
  font-family: "Readex Pro Bold";
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
}

.footer-logo {
  max-width: 45px;
  max-height: 45px;
  vertical-align: middle;
}

.footer {
  padding-bottom: 30px;
}

.million-logo {
  max-height: 55px;
  max-width: 55px;
}

.ant-form-item-label {
  font-family: "Readex Pro Bold";
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
}

.ant-input {
  background-color: #1F1F1F;
}

.ant-input-number-input {
  background-color: #1F1F1F;
}

.ant-form-item-control-input-content {
  text-align: left;
}

.ant-alert-message {
  font-family: "Readex Pro Medium";
  font-size: 1.17em;
}

.ant-alert-error {
  background-color: #8B5CF6;

  .ant-alert-icon {
    color: white;
  }
}

.ant-alert-info {
  background-color: #8B5CF6;

  .ant-alert-icon {
    color: white;
  }
}

.ant-alert-warning {
  background-color: #8B5CF6;

  .ant-alert-icon {
    color: white;
  }
}

.ant-modal-title {
  font-family: "Readex Pro Bold";
}

.ant-dropdown-menu-title-content {
  font-family: "Readex Pro Medium";
}

.continue-button-gradient {
  width: 100%;
  background: #8657FD;
  background: linear-gradient(to right, #ff2598, #8B5CF6, #00aceb);
  border-radius: 20px;
}

div.ant-alert {
  width: 97%;
  border-radius: 20px;
}

.ant-btn {
  border-radius: 20px;
  font-family: "Readex Pro Medium";
}

.arrow-select {
  color: #8657FD;
}

.swap-select {
  border-color: #8657FD;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.invalid-input-border {
  border-color: black;
}

.multi-pool-icon {
  float: left;
}

.pool-panel {
  border-radius: 20px;
  padding: 10px;
  background-color: #0A0A0A;
  margin-bottom: 15px;
}

.pool-panel-deposit-button {
  background: #ff2598;
  //background: linear-gradient(to right, #ff2598, #8B5CF6, #00aceb);
  border-radius: 20px;
}

.ant-slider-handle {
  margin-top: -7.5px;
  width: 20px;
  height: 20px;
  border: solid 2px #ff2598;
}

.ant-progress-text {
  font-family: "Readex Pro Medium";
}

.appbar-menu-button {
  cursor: pointer;
  margin-right: 15px;
  margin-top: 8px;
  font-family: "Readex Pro Medium";

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

.appbar-menu-button:hover {
  color: #ff2598;
}

.pink-highlight {
  color: #ff2598;
}

.swap-outlined-icon {
  cursor: pointer;
  padding: 7.5px;
  border: solid 3px #1F1F1F;
  border-radius: 50%;
  font-size: 24px;
  margin-top: 4px;
  margin-bottom: 10px;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

.swap-outlined-icon:hover {
  background-color: #262626;
}

.home-token-display {
  margin-right: 7px;
}

@media only screen and (max-width: 600px) {
  .app-title {
    display: none;
  }

  .pool-panel {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .appbar-menu-button {
    font-size: 1.5em;
  }
}


@primary-color: #FE2598;
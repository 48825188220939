body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-row {
  min-width: 100%;
}

.builton {
  background-image: url("components/builton.svg");
  width: 300px;
  height: 50px;
  background-size: 300px;
  background-repeat: no-repeat;
  margin: 0 auto 0 auto;
  padding-bottom: 10px;
}
